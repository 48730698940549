button {
  border: 0 !important;
}

.btn {
  border-radius: 40px;
  padding: 15px 64px;
  color: #FFFFFF;
  font-size: 1.5rem;
  display: inline-block;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 0 3rem rgba(0, 0, 0, 0.1);
  }
}

.btn-primary {
  background-color: $primary-color;
  color: $white-color;
  text-transform: none;

  &:hover {
    box-shadow: 0 10px 14px rgba($primary-color, 0.4);
  }
}